var key = {};
var NODE_ENV = "production";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

if (NODE_ENV === "production") {

  key = {
    partialUpload: 300,
    BUSDcontract: "0x55d398326f99059fF775485246999027B3197955",
    ExcelUploadContract: "0xc76Aaf250B1D6714334cE3FFC3403EBc88a45c6d",
    ScanUrl: "https://bscscan.com/tx/",
    NetworkId: 56,
    decimals: 1e18,
    infuraRpc: "https://bsc.publicnode.com",
    toasterOption: toasterOption,
  };

} else {
  key = {
    partialUpload: 150,
    BUSDcontract: "0x8A7dcB46d9b598CfC2EeD49aD19ecB39a2305Ee9",
    ExcelUploadContract: "0x359c58A3cB0887509FDEeAaAFE26f3b662A3d4f2",
    //ExcelUploadContract: "0xCd7e6D9C52C3e3775f5cd473B1DB7Acd52438c8a",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18,
    infuraRpc: "https://bsc-testnet.publicnode.com",
    toasterOption: toasterOption,
  };
}
export default key;

